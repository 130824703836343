import React from 'react';
import { Helmet } from 'react-helmet';

import { css } from '@emotion/core';

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import { PostFullContent } from '../components/PostContent';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  inner,
  outer,
  SiteArchiveHeader,
  SiteHeader,
  SiteMain,
  SiteNavMain,
} from '../styles/shared';
import { NoImage, PostFull, PostFullHeader, PostFullTitle } from '../templates/post';

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    background: #fff;
  }
`;

const About: React.FC = () => (
  <IndexLayout>
    <Helmet>
      <title>Om Arossy</title>
    </Helmet>
    <Wrapper css={PageTemplate}>
      <header className="site-archive-header no-image" css={[SiteHeader, SiteArchiveHeader]}>
        <div css={[outer, SiteNavMain]}>
          <div css={inner}>
            <SiteNav isHome={false} />
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main" css={[SiteMain, outer]}>
        <div css={inner}>
          <article className="post page" css={[PostFull, NoImage]}>
            <PostFullHeader className="post-full-header">
              <PostFullTitle className="post-full-title">Om Arossy</PostFullTitle>
            </PostFullHeader>

            <PostFullContent className="post-full-content">
              <div className="post-content">
                <h5>
                  Digital arbetsordning för din verksamhet
                </h5>
                <p>
                  Arossy låter dig digitalisera din arbetsordning.
                </p>
                <p>
                  Passar bra för snicken.
                </p>
                <p>
                  Passar även bra för betongen. 
                </p>
                <div>
                    <a href="/author/anders-kensby/">Anders Kensby</a>
                </div>
                <div>
                    <a href="/author/samuel-frylmark/">Samuel Frylmark</a>
                </div>
                <div>
                    <a href="/author/magnus-arinell/">Magnus Arinell</a>
                </div>
                <div>
                    <a href="/author/david-karlsson/">David Karlsson</a>
                </div>
              </div>
            </PostFullContent>
          </article>
        </div>
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export default About;
